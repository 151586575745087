<template>
    <form class="">
        <div class="form-group mb-3">
          <label for="attribute-name">
              Attribute name
              <span class="text-danger">*</span>
          </label>
          <input type="text" v-validate="{required: true}" v-model="data.name" id="attribute-name" name="name" class="form-control"
          :class="{ 'is-invalid': errors.has('name') }" placeholder="Name" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
        <div class="form-group mb-3">
          <label class="">Attribute Type</label>
          <select class="custom-select" v-validate="{required: true}" v-model="data.type" name="type" placeholder="--select type--">
            <option disabled value="">--select type--</option>
            <option value="check">check</option>
            <option value="input">input</option>
          </select>
          <span class="text-danger invalid-feedback"  v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="form-label" for="description">Description</label>
            <textarea id="description"
                v-model="data.description" class="form-control" rows="2"
                placeholder="Write description" name="description"></textarea>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
    </form>
</template>

<script>
export default {
    props:{
        productId:{
            type: Number,
            default: null
        },
        model:{
            default: "PRODUCT"
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                type: "",
                description: "",
                model: this.model,
                product_id: this.productId
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/attributes/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("attributeList/ADD_ATTRIBUTE", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
           this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/attributes/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("attributeList/UPDATE_ATTRIBUTE", response.data.data)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>