
<template>
  <div class="attribute-list">
    <div v-if="!isLoading" class="mt-3 row">
      <div class="col-md-6 mb-2" v-for="(attribute, index) in attributes" :key="index">
          <div class="list-group-item d-flex align-items-center
            justify-content-between">
            <span class="font-weight-bolder">
              <i class="mdi mdi-bookmark-check-outline mr-1"></i>{{attribute.name}} -  {{attribute.type}}</span>
            <span>
              <b-dropdown variant="light" right size="sm">
                <template slot="button-content">
                    Action
                    <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item-button @click="initEdit(attribute)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                <b-dropdown-item-button @click="deleteItem(attribute.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
              </b-dropdown>
            </span>
          </div>
      </div>
    </div>
    <is-loading v-else />
    <b-modal id="attribute-List-modal" hide-footer centered
      :title="editMode? 'Update attribute':'Add attribute'"
      title-class="font-18" @hide="initializeAll()">
      <attribute-form @closeMe="initializeAll()"
      :editMode="editMode" :model="model" :productId="productId" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>
import AttributeForm from '@/components/forms/AttributeForm.vue'
import IsLoading from '@/components/IsLoading.vue'

export default {
  components:{AttributeForm, IsLoading},
  props:{
    productId:{
      type: Number,
    },
    model: {
      default: "PRODUCT"
    },
  },
  data() {
    return {
      isLoading: true,
      editMode: false,
      editItem: null,
    }
  },
  computed: {
    attributes(){
      return this.$store.state.attributeList.data
    },
  },
  methods: {
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this attribute!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/attributes/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$store.commit("attributeList/DELETE_ATTRIBUTE", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.$emit("toggleModal", true)  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.$emit("toggleModal")  
    },
    fetchItems(){
      let payload = {model: this.model }
      if(this.productId){ payload.product_id = this.productId }
      this.$store.dispatch("attributeList/fetchAttributes", payload)
      .then((_) => { this.isLoading = false })
    }
  },
  mounted(){
    this.fetchItems()
  }
}
</script>
