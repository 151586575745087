
<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <div class="card shadow-sm border">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="my-0">
                Attributes
              </h3>
              <b-button variant="primary" class=""
                @click.prevent="toggleModal(true)" type="button">
              <i class="mdi font-14 mdi-plus"></i> <span class="d-none d-sm-inline"> Add Attribute</span></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <attribute-list model="TASK"
          @toggleModal="toggleModal($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import AttributeList from "@/components/AttributeList.vue"
export default {
  components:{
    AttributeList,
  },
  methods:{
    toggleModal(val = false){
      val ? this.$bvModal.show("attribute-List-modal")
      : this.$bvModal.hide("attribute-List-modal")
    }   
  }
}
</script>
